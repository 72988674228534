import { css } from "aphrodite";
import { List } from "immutable";
import range from "lodash/range";
import CarouselMaybe from "nuka-carousel";
import PropTypes from "prop-types";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { onlyUpdateForKeys } from "recompose";

import RequestContext from "pages/RequestContext";

import generateTransition from "utils/generateTransition";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";

const Carousel = CarouselMaybe.default || CarouselMaybe;

const baseStyles = {
  carouselListItem: {
    // need this to make sure the card drop shadow doesn't get cut off by the slider
    marginBottom: "10px",
  },
  carouselWrapper: {
    display: "flex",
    flex: 1,
    marginBottom: 12,
  },

  controlsContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
  },
  control: {
    transition: generateTransition({
      target: "background-color",
      speed: "400ms",
    }),
    width: 17,
    height: 17,
    border: "5px solid rgb(221, 221, 221)",
    backgroundColor: "rgb(221, 221, 221)",
    borderRadius: "50%",
    margin: 6,
  },
  currentControl: {
    backgroundColor: colours.oldSecondary,
  },
};

const nullFunction = () => null;

const MOVE_KEY = "moveButtonGoesHere";

const preventScroll = (e) => {
  if (e && e.cancelable) {
    e.preventDefault();
  }
};

const ListCarousel = (props) => {
  const {
    carouselAtSize,
    carousel,
    items,
    renderItem,
    minCarouselItemPercentage,
    cellSpacing,
    noEndPadding,
    renderMore,
    displaySize,
    showControls,
    desiredWidth,
    className,
  } = props;

  const { isWindowSizeOrLess, getWindowWidth } = useWindowSize();
  const isAtCarouselSize = carouselAtSize && isWindowSizeOrLess(carouselAtSize);
  const screenWidth = getWindowWidth();

  const requestContext = useContext(RequestContext);

  const handleTouchStarted = useCallback(() => {
    document.addEventListener("touchmove", preventScroll, { passive: false });
  }, []);

  const handleTouchEnded = useCallback(() => {
    document.removeEventListener("touchmove", preventScroll);
  }, []);

  useEffect(() => () => handleTouchEnded(), [handleTouchEnded]);

  const carouselItems = useMemo(() => {
    let newItems = items ? items.slice() : [];

    if (
      displaySize &&
      (displaySize < items.length || displaySize < items.size)
    ) {
      newItems = newItems.slice(0, displaySize);
    }
    if (renderMore && (carousel || carouselAtSize) && items) {
      newItems = newItems.push(MOVE_KEY);
    }

    return newItems;
  }, [items, renderMore, displaySize, carousel, carouselAtSize]);

  const itemStyles = useMemo(() => {
    if (items && items.length > 0) {
      return items.reduce(
        (newStyles, item, index) => ({
          ...newStyles,
          [`carouselListItem${index}`]: {
            paddingLeft: !noEndPadding && index === 0 && cellSpacing,
            paddingRight:
              !noEndPadding && index === items.length - 1 && cellSpacing,
          },
        }),
        {}
      );
    }

    return {};
  }, [items, noEndPadding, cellSpacing]);

  const { styles } = useStyles([baseStyles, itemStyles], props);

  const slideWidth = useMemo(() => {
    const minWidthPercentage = minCarouselItemPercentage / 100;
    let widthPercentage = desiredWidth / screenWidth;

    if (widthPercentage > minWidthPercentage) {
      widthPercentage = minWidthPercentage;
    }

    return screenWidth * widthPercentage;
  }, [minCarouselItemPercentage, desiredWidth, screenWidth]);

  const renderCarouselItem = (renderFunc, item, index) => (
    <div
      key={`carousel-item-${index}`}
      className={css(
        styles.carouselListItem,
        styles[`carouselListItem${index}`]
      )}
    >
      {renderFunc(item, index, true)}
    </div>
  );

  const renderControls = (slideControlsProps) => {
    const { goToSlide, currentSlide, slideCount } = slideControlsProps;

    return (
      <div className={css(styles.controlsContainer)}>
        {range(slideCount).map((slideIndex) => (
          <div
            key={`control-${slideIndex}`}
            className={css(
              styles.control,
              currentSlide === slideIndex && styles.currentControl
            )}
            onClick={() => goToSlide(slideIndex)}
          />
        ))}
      </div>
    );
  };

  const renderCarousel = () => (
    <div
      className={`${css(styles.carouselWrapper)} ${className}`}
      onTouchEnd={handleTouchEnded}
    >
      <Carousel
        cellSpacing={cellSpacing}
        initialSlideWidth={requestContext.server ? slideWidth : null}
        initialSlideHeight={requestContext.server ? slideWidth * 1.5 : null}
        slideWidth={`${slideWidth}px`}
        renderCenterLeftControls={nullFunction}
        renderCenterRightControls={nullFunction}
        renderBottomCenterControls={showControls ? renderControls : null}
        swipeDeadZone={20}
        beforeSlide={handleTouchStarted}
        afterSlide={handleTouchEnded}
      >
        {carouselItems.map((item, index) => {
          if (renderMore && item === MOVE_KEY) {
            return renderCarouselItem(renderMore, item, index);
          }

          return renderCarouselItem(renderItem, item, index);
        })}
      </Carousel>
    </div>
  );

  if (!items) {
    return null;
  }
  if (carousel || isAtCarouselSize) {
    return renderCarousel();
  }

  return carouselItems.map((item, index) => renderItem(item, index));
};

ListCarousel.propTypes = {
  renderItem: PropTypes.func.isRequired,
  items: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.array]),
  carouselAtSize: PropTypes.string,
  carousel: PropTypes.bool,
  minCarouselItemPercentage: PropTypes.number,
  cellSpacing: PropTypes.number,
  noEndPadding: PropTypes.bool,
  renderMore: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  displaySize: PropTypes.number,
  showControls: PropTypes.bool,
  desiredWidth: PropTypes.number,
  className: PropTypes.string,
};

ListCarousel.defaultProps = {
  items: null,
  carouselAtSize: null,
  carousel: false,
  minCarouselItemPercentage: 85,
  cellSpacing: 15,
  noEndPadding: false,
  renderMore: null,
  displaySize: null,
  showControls: false,
  desiredWidth: 380,
  className: "",
};

export default onlyUpdateForKeys([
  "renderItem",
  "items",
  "carouselAtSize",
  "carousel",
  "minCarouselItemPercentage",
  "cellSpacing",
  "noEndPadding",
  "renderMore",
  "displaySize",
  "showControls",
  "desiredWidth",
  "className",
])(ListCarousel);
